export const Arrow = () => {
  return (
    <svg viewBox="0 0 7 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14L1 7.5h0L6 1"
        stroke="#142B68"
        strokeWidth="2"
        fill="none"
        opacity=".567"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowUp = () => {
  return (
    <svg viewBox="0 0 15 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 0l-.824.675L0 6.147 1.649 8 7.5 3.202 13.351 8 15 6.147 8.324.675z" fillRule="evenodd" />
    </svg>
  )
}

export const Check = () => {
  return (
    <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="a">
          <stop stopColor="#46F9C0" offset="0%" />
          <stop stopColor="#55E1C9" offset="100%" />
        </linearGradient>
      </defs>
      <path d="M0 9.363L2.222 6.16 8 11.58 17.334 0 20 2.71 8.222 17z" fill="url(#a)" fillRule="evenodd" />
    </svg>
  )
}

export const ChevronRight = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33337 5.5L13.3334 10.5L8.33337 15.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Cross = () => {
  return (
    <svg viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.364 1.364l12.374 12.374m-12.374.354L13.738 1.718"
        stroke="#142B68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Electricity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={40} height={41} viewBox="0 0 40 41">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.0162 4.3c.5335.1553.9005.6444.9005 1.2001v10.4167H30a1.25 1.25 0 0 1 1.0546 1.9211L19.3879 36.1712a1.2499 1.2499 0 0 1-1.404.529 1.2499 1.2499 0 0 1-.9006-1.2001V25.0834H10a1.2499 1.2499 0 0 1-1.0546-1.9211L20.6121 4.829a1.25 1.25 0 0 1 1.4041-.529Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const Gas = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={40} height={40} viewBox="0 0 40 40">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.5761 7.1525c.2568-.2204.6277-.1997.8616.048 1.3269 1.406 2.6823 2.3605 4.0037 3.2892l.0266.0187c1.1566.8128 2.3088 1.6226 3.3102 2.691C29.9259 15.4911 31 18.4961 31 21.4988s-1.0741 6.0077-3.2218 8.2994c-.8414.8977-1.7861 1.6204-2.7939 2.167-.266.1443-.5899.0758-.7842-.1657-.1943-.2416-.2087-.5936-.0347-.8523.2404-.3574.364-.7234.364-1.298 0-.6442-.0042-1.1429-.136-1.6271-.1246-.4572-.3759-.9388-.9409-1.4747-.2564-.2431-.4994-.3556-.8719-.5212l-.0084-.0037c-.3614-.1607-.8087-.3596-1.3313-.769-.4106-.3218-.8532-.7614-1.3489-1.3949-.5556.5499-1.1079 1.3103-1.5543 2.1517-.5771 1.0876-.9259 2.2136-.9259 3.0831 0 1.3384.6373 2.3583.9579 2.7831.1715.2272.1906.545.0478.7938-.1429.2487-.4169.3748-.6846.315-2.0198-.4519-3.9439-1.5149-5.5111-3.1871C10.0741 27.5065 9 24.5015 9 21.4988s1.0741-6.0077 3.2218-8.2994c.1541-.1644.3753-.235.5886-.1879.213.047.3895.2049.47.4204l-.601.2557c.6015-.2545.601-.2557.601-.2557l.004.01c.0045.0112.0123.0304.0238.0567.0229.0528.06.1344.1131.2386.1061.2086.2752.5066.5198.8463a7.24 7.24 0 0 0 1.568 1.5882c.1814-2.7828 1.0728-6.4488 4.067-9.0192Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const Help = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#ABAEC8" />
      <path
        d="M8.47903 4.27733C9.62392 4.27733 10.4992 4.50963 11.1048 4.97422C11.7104 5.43881 12.0133 6.07763 12.0133 6.89066C12.0133 8.3674 10.9223 9.19703 8.74037 9.37955V9.92711H7.12259V8.11022C8.15963 8.11022 8.91044 8.02311 9.37503 7.84889C9.83963 7.66637 10.0719 7.36355 10.0719 6.94044C10.0719 6.592 9.92259 6.31822 9.62392 6.11911C9.33355 5.92 8.90629 5.82044 8.34215 5.82044C7.30511 5.82044 6.37592 6.11496 5.55459 6.704L4.83281 5.32266C5.80348 4.62577 7.01889 4.27733 8.47903 4.27733ZM7.13503 11.2213C7.35074 11.0222 7.61622 10.9227 7.93148 10.9227C8.24674 10.9227 8.50807 11.0222 8.71548 11.2213C8.93118 11.4204 9.03903 11.6735 9.03903 11.9804C9.03903 12.2874 8.93118 12.5404 8.71548 12.7396C8.50807 12.9387 8.24674 13.0382 7.93148 13.0382C7.61622 13.0382 7.35074 12.9387 7.13503 12.7396C6.92763 12.5404 6.82392 12.2874 6.82392 11.9804C6.82392 11.6735 6.92763 11.4204 7.13503 11.2213Z"
        fill="white"
      />
    </svg>
  )
}

export const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
      <path d="M165 0C74.019 0 0 74.02 0 165.001 0 255.982 74.019 330 165 330s165-74.018 165-164.999S255.981 0 165 0zm0 300c-74.44 0-135-60.56-135-134.999S90.56 30 165 30s135 60.562 135 135.001C300 239.44 239.439 300 165 300z" />
      <path d="M164.998 70c-11.026 0-19.996 8.976-19.996 20.009 0 11.023 8.97 19.991 19.996 19.991 11.026 0 19.996-8.968 19.996-19.991 0-11.033-8.97-20.009-19.996-20.009zM165 140c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" />
    </svg>
  )
}

export const Leaf = () => {
  return (
    <svg viewBox="0 0 48 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.954 34.788c-5.372-6.266-12.924-13.19-18.293-17.969-.337-.33-.504-.658-.504-1.153 0-.99.67-1.647 1.678-1.647.336 0 .84.163 1.174.33h.166c7.553 6.76 13.595 12.529 17.96 17.475.337-.659.67-1.32 1.007-1.979 0 0 0-.163.167-.33.166-.495.336-1.153.503-1.648 0-.164.167-.33.167-.495.167-.494.167-.989.337-1.483 0-.164 0-.331.166-.659-.166-.664-.166-1.49-.166-2.148 0-12.86-13.762-19.62-26.853-19.784C6.546 3.134 0 0 0 0s1.677 16.816 5.202 26.213C9.233 36.93 16.616 41.218 26.856 41.218c.67 0 1.344 0 2.014-.164h.167c.503 0 1.173-.164 1.677-.33h.167c1.174-.331 2.35-.66 3.524-1.154 1.007-.494 2.014-.989 3.021-1.647 3.358 3.793 5.539 6.76 5.539 8.077H48c-.167-2.476-3.018-6.76-7.046-11.212z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const List = () => {
  return (
    <svg viewBox="0 0 14 7" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 .75v-.5h13.404v.5H0zm0-.5h13.404v.5H0v-.5zm0 3.5v-.5h13.404v.5H0zm0-.5h13.404v.5H0v-.5zm0 3.5v-.5h13.404v.5H0zm0-.5h13.404v.5H0v-.5z" />
    </svg>
  )
}

export const Map = () => {
  return (
    <svg viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.673.016L.205 1.543A.313.313 0 0 0 0 1.835v8.863c.002.191.214.346.391.287L4.766 9.49l4.375 1.494c.06.02.126.02.186 0l4.468-1.528A.313.313 0 0 0 14 9.171V.307c0-.193-.212-.351-.391-.291L9.234 1.51 4.859.016a.287.287 0 0 0-.186 0zm-.205.711v8.224L.596 10.273V2.05L4.468.727zm.596 0L8.936 2.05v8.223L5.064 8.951V.727zm8.34 0v8.224l-3.872 1.322V2.05L13.404.727z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const Plus = () => {
  return (
    <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.947 9.304h-7.5c-.28 0-.46-.18-.46-.46v-7.5a.7.7 0 0 0-.68-.68h-1.36a.7.7 0 0 0-.68.68v7.5c0 .28-.18.46-.46.46h-7.5a.7.7 0 0 0-.68.68v1.36c0 .36.32.68.68.68h7.5c.28 0 .46.18.46.46v7.5c0 .36.32.68.68.68h1.36a.7.7 0 0 0 .68-.68v-7.5c0-.28.18-.46.46-.46h7.5a.7.7 0 0 0 .68-.68v-1.36c0-.36-.32-.68-.68-.68z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const Sun = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
      <path
        d="M26 0h7v7h-7zm0 51h7v7h-7zM0 26h7v7H0zm51 0h7v7h-7zM6 47l5-5 5 5-5 5zm36-36l5-5 5 5-5 5zm0 36l5-5 5 5-5 5zM6 11l5-5 5 5-5 5zm38 18.5C44 37.51 37.51 44 29.5 44S15 37.51 15 29.5 21.49 15 29.5 15 44 21.49 44 29.5"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const Water = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 49">
      <g fillRule="evenodd">
        <path d="M49.99 10c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.17-6.66-2.021C2.254 7.313 1.604 6.912 0 6.912V.003c3.392 0 5.282 1.17 6.661 2.021C7.74 2.69 8.39 3.091 9.993 3.091c1.605 0 2.258-.401 3.335-1.07C14.708 1.169 16.598 0 19.99 0c3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07C34.706 1.169 36.596 0 39.991 0c3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 1.169 56.606 0 60 0v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 8.834 53.384 10 49.99 10z" />
        <path d="M49.99 10c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.17-6.66-2.021C2.254 7.313 1.604 6.912 0 6.912V.003c3.392 0 5.282 1.17 6.661 2.021C7.74 2.69 8.39 3.091 9.993 3.091c1.605 0 2.258-.401 3.335-1.07C14.708 1.169 16.598 0 19.99 0c3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07C34.706 1.169 36.596 0 39.991 0c3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 1.169 56.606 0 60 0v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 8.834 53.384 10 49.99 10zm0 13c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.17-6.66-2.021C2.254 20.313 1.604 19.912 0 19.912v-6.909c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.067 3.332 1.067 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 14.169 56.606 13 60 13v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 21.834 53.384 23 49.99 23z" />
        <path d="M49.99 23c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.17-6.66-2.021C2.254 20.313 1.604 19.912 0 19.912v-6.909c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.067 3.332 1.067 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 14.169 56.606 13 60 13v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 21.834 53.384 23 49.99 23zm0 13c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.166-6.66-2.021c-1.078-.666-1.728-1.07-3.333-1.07V26c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.07 3.332 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 27.169 56.606 26 60 26v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 34.831 53.384 36 49.99 36z" />
        <path d="M49.99 36c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.166-6.66-2.021c-1.078-.666-1.728-1.07-3.333-1.07V26c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.07 3.332 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 27.169 56.606 26 60 26v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 34.831 53.384 36 49.99 36zm0 13c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.166-6.66-2.021c-1.078-.666-1.728-1.07-3.333-1.07V39c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.07 3.332 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 40.169 56.606 39 60 39v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 47.831 53.384 49 49.99 49z" />
        <path d="M49.99 49c-3.395 0-5.285-1.166-6.665-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021-3.391 0-5.281-1.166-6.664-2.021-1.08-.666-1.73-1.07-3.337-1.07-1.605 0-2.258.401-3.335 1.07-1.38.852-3.27 2.021-6.662 2.021s-5.281-1.166-6.66-2.021c-1.078-.666-1.728-1.07-3.333-1.07V39c3.392 0 5.282 1.17 6.661 2.021 1.078.666 1.728 1.07 3.332 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.662-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.337 1.07 1.605 0 2.258-.401 3.335-1.07 1.38-.852 3.27-2.021 6.665-2.021 3.391 0 5.284 1.166 6.664 2.021 1.08.666 1.73 1.07 3.34 1.07 1.608 0 2.26-.401 3.34-1.07C54.716 40.169 56.606 39 60 39v6.909c-1.608 0-2.26.401-3.338 1.07C55.274 47.831 53.384 49 49.99 49z" />
      </g>
    </svg>
  )
}

export const Wind = () => {
  return (
    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37 34.5c0 3.59-3.133 6.5-7 6.5s-7-2.91-7-6.5 3.133-6.5 7-6.5 7 2.91 7 6.5M6.07 30.982c.7.118 1.404-.357 1.52-1.069.936-5.938 4.093-11.162 8.888-14.603.585-.475.7-1.187.233-1.78-.468-.594-1.169-.712-1.753-.237-5.38 3.797-9.004 9.614-9.94 16.264-.117.711.351 1.307 1.052 1.425zm45.357-1.063c.116.708.814 1.18 1.51 1.063.696-.118 1.161-.828 1.045-1.536-1.16-6.5-4.646-12.288-9.988-16.189-.58-.472-1.394-.235-1.74.236-.466.59-.232 1.418.23 1.77 4.878 3.666 8.016 8.864 8.943 14.656zM39.116 56.184c-5.625 2.006-11.73 1.9-17.352-.105-.717-.21-1.436 0-1.675.633-.238.632 0 1.268.717 1.478 6.104 2.323 12.926 2.428 19.387.21.717-.21.957-.95.717-1.478-.358-.633-1.196-.95-1.794-.738zM26 42.562c-2.76-1.512-4.682-4.306-4.92-7.562C10.035 37.096-1.008 43.959.072 53.383 8.595 58.385 19.64 50.939 26 42.562zM38.92 35c-.239 3.258-2.16 6.052-4.92 7.565 6.602 8.728 17.525 15.711 25.927 10.823C61.007 43.962 49.843 37.097 38.92 35zm-4.64-8C36.01 22.927 37 18.273 37 14.082 37 7.915 34.899 2.445 29.46 0c-9.023 4.189-8.9 16.873-4.697 27 3.09-1.632 6.55-1.632 9.518 0z"
        fillRule="evenodd"
      />
    </svg>
  )
}
